export const FirebaseConfigDev = {
  apiKey: "AIzaSyCF8GRiDxKTeK-M1C8kXqD79KUDNhGdqcM",
  authDomain: "cccv-dev.firebaseapp.com",
  projectId: "cccv-dev",
  storageBucket: "cccv-dev.appspot.com",
  messagingSenderId: "154970222016",
  appId: "1:154970222016:web:9c05789f58c9a286bfac25",
  measurementId: "G-RD3R0G4E5C"
};

export const FirebaseConfigProd = {
  apiKey: "AIzaSyCmIXeQuhX9oLQhiPFunwBzB2UM-o5qfBU",
  authDomain: "cccv-288905.firebaseapp.com",
  projectId: "cccv-288905",
  storageBucket: "cccv-288905.appspot.com",
  messagingSenderId: "392959136562",
  appId: "1:392959136562:web:d9f0ec1aed28a1a9c07d2b",
  measurementId: "G-VMZTK13WVQ"
};
