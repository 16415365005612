<template>
  <div class="admin-login-page" v-if="initialized === true">
    <h1 class="title">운영자 로그인</h1>
    <div class="container">
      <button class="social-button kakao" @click="kakaoLogin">
        <span class="kakao">카카오 로그인</span>
      </button>
      <button class="social-button naver" @click="naverLogin">
        <span class="naver">네이버 로그인</span>
      </button>
      <button class="social-button google" @click="googleLogin">
        <span class="google">구글 로그인</span>
      </button>
    </div>
  </div>
</template>

<script>
import { FirebaseConfigDev, FirebaseConfigProd } from "@/config/FirebaseConfig";
import {
  getAuth,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAuth as getUserAuth } from "@/common/auth.service";

export default {
  name: "Login",
  data() {
    return {
      initialized: false
    };
  },
  async mounted() {
    try {
      if (getUserAuth()) {
        this.$router.push("/admin/user");
      }

      initializeApp(
        String(process.env.REACT_APP_DEBUG).toLowerCase() === "true"
          ? FirebaseConfigDev
          : FirebaseConfigProd
      );

      // Firebase 로그인을 통해 redirect callback으로 들어왔는지 확인한다.
      const auth = getAuth();
      const googleRedirect = await getRedirectResult(auth);

      if (googleRedirect) {
        // 로그인 정보를 얻는다.
        const credential = GoogleAuthProvider.credentialFromResult(
          googleRedirect
        );

        const { accessToken, idToken } = credential;

        // CCCV Login
        this.$router?.push(
          `/_o/google?access_token=${accessToken}&id_token=${idToken}`
        );
      }

      this.initialized = true;
    } catch (err) {
      console.error(err);
      this.initialized = true;
    }
  },
  methods: {
    kakaoLogin() {
      window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.VUE_APP_KAKAO_REDIRECT_URL}`;
    },
    naverLogin() {
      window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_NAVER_CLIENT_ID}&state=RANDOM_STATE&redirect_uri=${process.env.VUE_APP_NAVER_REDIRECT_URL}`;
    },
    googleLogin() {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      const auth = getAuth();
      signInWithRedirect(auth, provider);
    }
  }
};
</script>

<style scoped>
.admin-login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
}

.container {
  margin-top: 50px;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 55px;
  border-radius: 10px;
  border: none;
}

.social-button.kakao {
  background-color: #fae300;
}

.social-button.naver {
  background-color: #06be34;
}

.social-button.google {
  background-color: #3e82f1;
}

.social-button + .social-button {
  margin-top: calc(100vw * 12 / 1920);
}

.social-button span {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 28px;
  text-align: left;
}

.social-button span.kakao {
  color: #391b1b;
}

.social-button span.naver {
  color: #ffffff;
}

.social-button span.google {
  color: #ffffff;
}
</style>
